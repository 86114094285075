import { useState, useEffect } from 'react'
import { StorageKeys } from 'common/unique'

type UseLocalStorage<T> = [
   T,
   React.Dispatch<React.SetStateAction<T>>
]

export function useLocalStorage<T>(key: StorageKeys, defaultValue: T): UseLocalStorage<T>
{
   const [ value, setValue ] = useState<T>(() => {
      const storageItem = localStorage.getItem(key)
      const storageEmpty = (storageItem === null)

      const storageValue = (() => {
         try {
            if (storageEmpty) throw 1 // eslint-disable-line
            return JSON.parse(storageItem)
         }
         catch (err) {
            return defaultValue
         }
      })()

      return (typeof storageValue === 'string'
         ? storageValue.trim()
         : storageValue
      )
   })

   useEffect(() => {
      if (value !== defaultValue) {
         localStorage.setItem(key, JSON.stringify(value))
      } else {
         localStorage.removeItem(key)
      }
   }, [key, value, defaultValue])

   return [ value, setValue ]
}
