import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { FormattedApiException } from 'common/types/api.types'
import { formatApiException } from 'utils/formatApiException'

interface UseApiConfig {
   sendWhenMount?: boolean
   ignoreError?: boolean
}

type UseApiResponse<D> = [
   {
      data: D | null
      loading: boolean
      error: FormattedApiException | null
   },
   (drop?: boolean) => void,
   () => void
]

export function useApi<D>(method: () => Promise<D>, { sendWhenMount, ignoreError }: UseApiConfig = {}): UseApiResponse<D>
{
   const [ data,    setData    ] = useState<D | null>(null)
   const [ loading, setLoading ] = useState<boolean>(!!sendWhenMount)
   const [ error,   setError   ] = useState<FormattedApiException | null>(null)

   const execute = useCallback((drop = true) => {
      if (drop) setData(null)
      setLoading(true)
      setError(null)
   }, [])

   const reset = useCallback(() => {
      setData(null)
      setLoading(false)
      setError(null)
   }, [])

   useEffect(() => {
      let isMounted = true

      const sendQuery = async () => {
         try {
            const response = await method()

            if (isMounted) {
               setData(response)
               setLoading(false)
               setError(null)
            }
         }
         catch (err) {
            const exception = formatApiException(err)

            if (isMounted) {
               setData(null)
               setLoading(false)
               setError(exception)
            }
         }
      }

      if (loading) {
         sendQuery()
      }

      return () => { isMounted = false }
   }, [method, loading])

   useEffect(() => {
      if (!ignoreError && error) toast.error(error.message)
   }, [ignoreError, error])

   return [ { data, loading, error }, execute, reset ]
}
