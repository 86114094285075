import { FC, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { classNames } from 'utils/classNames'
import { useSelector } from 'hooks/useSelector'

import { Loader } from 'components/shared/Loaders/Loader'
import { Button } from 'components/shared/Button'
import { Flow } from 'components/shared/Animations/Flow'

import css from './Layout.module.scss'

export const Layout: FC = () => {
   const location = useLocation()
   const $user = useSelector(state => state.user)

   return (
      <div className={classNames(css.page, 'm0auto')}>
         {$user && (
            <div className={css.header}>
               <Flow
                  type="fade-left"
                  active={location.pathname === PathsConfig.HOME()}
               >
                  <Button
                     className={css.settings}
                     ico="settings"
                     theme="icon"
                     href={PathsConfig.SETTINGS()}
                  />
               </Flow>

               <Flow
                  type="fade-left"
                  active={location.pathname !== PathsConfig.HOME()}
               >
                  <Button
                     className={css.home}
                     ico="home"
                     theme="icon"
                     href={PathsConfig.HOME()}
                  />
               </Flow>
            </div>
         )}

         <Suspense fallback={<Loader />}>
            <Outlet />
         </Suspense>
      </div>
   )
}
