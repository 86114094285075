import { Dispatch } from 'redux'
import { UserSchema } from 'common/types/schemas/user.schema'
import { UserActions, UserAction } from './user.types'

export function UserSet (user: UserSchema | null)
{
   return async (dispatch: Dispatch<UserAction>) => {
      dispatch({ type: UserActions.SET, payload: user })
   }
}
