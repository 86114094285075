import { classNames } from 'utils/classNames'
import { Icon, Icons } from 'components/shared/Icon'
import { Button } from 'components/shared/Button'
import { AlertProps, AlertThemes } from './Alert.types'
import css from './Alert.module.scss'

const icons: Record<AlertThemes, Icons> = {
   'primary': 'logo',
   'info': 'info',
   'success': 'success',
   'warning': 'warning',
   'error': 'error',
   'primary-inner': 'logo',
   'info-inner': 'info',
   'success-inner': 'success',
   'warning-inner': 'warning',
   'error-inner': 'error'
}

export const Alert = ({
   className,
   ico,
   label,
   theme = 'primary',
   close,
   button,
   onClick,
   disabled
}: AlertProps) => (
   <div className={classNames(css.alert, css[theme], className, { [css.padding]: close })}>
      <div className={css.container}>
         <div className={css.icon}>
            <Icon ico={ico || icons[theme]} />
         </div>

         <p className={css.text}>
            {label}
         </p>
      </div>

      {close && (
         <Button
            className={css.close}
            ico="close"
            theme="icon"
            onClick={onClick}
         />
      )}

      {button && (
         <Button
            className={css.button}
            label={button}
            theme="custom"
            onClick={onClick}
            disabled={disabled}
         />
      )}
   </div>
)
