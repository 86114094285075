import { FC, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { toast } from 'react-toastify'

import { authService } from 'common/services/auth'
import { ucFirstAll } from 'utils/ucFirst'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'
import { useApi } from 'hooks/useApi'

import { Spinner } from 'components/shared/Loaders/Spinner'
import { PathsConfig } from 'configs/paths.config'

export const Refresh: FC = () => {
   const auth = useMatch(PathsConfig.AUTH(':token'))
   const $user = useSelector(state => state.user)
   const { UserSet } = useActions()

   const [config, execute] = useApi(authService.refresh, {
      ignoreError: true
   })

   useEffect(() => {
      if (!auth && !$user) execute()
   }, [auth, $user]) // eslint-disable-line

   useEffect(() => {
      if (config.data) {
         toast.success(`Вы успешно авторизованы как "${ucFirstAll(config.data.name)}"`)
         UserSet(config.data)
      }
   }, [config.data]) // eslint-disable-line

   useEffect(() => {
      if (config.error && config.error.status !== 401) toast.error(config.error.message)
   }, [config.error])

   return config.loading ? (
      <Spinner
         mode="fixed"
         position="bottom-right"
      />
   ) : null
}
