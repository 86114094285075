import { config } from '../config'
import { Engine } from '../engine/Engine'
import { Tools } from '../engine/Tools'
import { Vector2 } from '../engine/Vector2'
import { random } from '../engine/random'
import { Model } from './Model.class'

interface PlusProps {
   bPos: Vector2
   bSize: Vector2
   pos: Vector2
   size: number
   ang: number
   vel: number
   sang: number
   rvel: number
   color: string
}

export class Plus extends Model
{
   private bPos: Vector2
   private bSize: Vector2
   private pos: Vector2
   private size: number
   private ang: number
   private vel: number
   private dir: Vector2
   private sang: number
   private rvel: number
   private color: string

   constructor (engine: Engine, tools: Tools, props: PlusProps)
   {
      super(engine, tools)

      this.bPos = props.bPos
      this.bSize = props.bSize

      this.pos = props.pos
      this.size = props.size
      this.ang = props.ang
      this.vel = props.vel

      const radians = Vector2.radians(this.ang)
      this.dir = new Vector2(Math.cos(radians), Math.sin(radians)).mul(this.vel)

      this.sang = props.sang
      this.rvel = props.rvel
      this.color = props.color
   }

   private updatePosition (): void
   {
      this.pos.add(this.dir)

      const pos = this.pos.clone().add(this.size / 2)
      const min = this.bPos
      const max = this.bPos.clone().add(this.bSize)

      if (pos.y > max.y || pos.y < min.y) this.dir.negate('y')
      if (pos.x > max.x || pos.x < min.x) this.dir.negate('x')
   }

   private updateSelfAngle (): void
   {
      this.sang += this.rvel
      if (this.sang > 360) this.sang = 0
      if (this.sang < 0) this.sang = 360
   }

   public update (): void
   {
      this.updatePosition()
      this.updateSelfAngle()
   }

   public render (): void
   {
      this.tools.rotate(this.pos, new Vector2(this.size, this.size), this.sang, (position) => {
         const width = Math.max(10, this.size / 10)
         const radius = width / 2

         this.tools.rectangle({
            pos: new Vector2((position.x + this.size / 2) - width / 2, position.y),
            size: new Vector2(width, this.size),
            rad: radius,
            fill: this.color
         })

         this.tools.rectangle({
            pos: new Vector2(position.x, (position.y + this.size / 2) - width / 2),
            size: new Vector2(this.size, width),
            rad: radius,
            fill: this.color
         })
      })

      if (config.DEV_MODE) {
         this.tools.rectangle({
            pos: this.bPos,
            size: this.bSize,
            type: 'stroke',
            stroke: 'green',
            width: 1
         })

         this.tools.circle({
            pos: this.pos.clone().add(this.size / 2),
            rad: 5,
            type: 'fill',
            fill: 'green'
         })
      }
   }

   public static randomize (engine: Engine, tools: Tools, position: Vector2): Plus
   {
      const pos = new Vector2(
         random.number(position.x - config.FIGURES_OFFSET, position.x + config.FIGURES_OFFSET),
         random.number(position.y - config.FIGURES_OFFSET, position.y + config.FIGURES_OFFSET),
      )

      return new Plus(engine, tools, {
         bPos: position.sub(config.FIGURES_DENSITY / 2),
         bSize: new Vector2(config.FIGURES_DENSITY, config.FIGURES_DENSITY),
         pos,
         size: random.numberByVector(config.FIGURES_RANDOM.PLUS.SIZE),
         ang: random.numberByVector(config.FIGURES_RANDOM.PLUS.ANGLE),
         vel: random.numberByVector(config.FIGURES_RANDOM.PLUS.VELOCITY),
         sang: random.numberByVector(config.FIGURES_RANDOM.PLUS.SELF_ANGLE),
         rvel: random.numberByVector(config.FIGURES_RANDOM.PLUS.ROTATE_VELOCITY),
         color: config.FIGURES_COLOR
      })
   }
}
