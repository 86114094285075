import { FC, useEffect, useRef } from 'react'
import { useSelector } from 'hooks/useSelector'
import { Figures } from './Figures'
import css from './Background.module.scss'

export const Background: FC = () => {
   const background = useSelector(state => state.main.background)
   const backgroundRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      const container = backgroundRef.current!
      const figures = new Figures(container)

      if (background) figures.start()
      else figures.stop()

      return () => figures.stop()
   }, [background])

   return (
      <div className={css.background} ref={backgroundRef} />
   )
}
