export const PathsConfig = {
   HOME: () => '/',

   TESTS: () => '/tests',
   TESTS_PUBLIC: () => '/tests/public',
   TESTS_CREATE: () => '/tests/create',
   TESTS_DETAILS: (testId: number | string) => `/tests/details/${testId}`,
   TESTS_PASS: (token: string) => `/tests/pass/${token}`,

   QUIZZES: () => '/quizzes',
   QUIZZES_PUBLIC: () => '/quizzes/public',
   QUIZZES_CREATE: () => '/quizzes/create',
   QUIZZES_DETAILS: (testId: number | string) => `/quizzes/details/${testId}`,
   QUIZZES_PASS: (token: string) => `/quizzes/pass/${token}`,

   USERS: () => '/users',
   SETTINGS: () => '/settings',

   REGISTER: () => '/register',
   LOGIN: () => '/login',
   AUTH: (token: string) => `/auth/${token}`,

   SUPPORT: () => '/support',
   FORBIDDEN: () => '/403',
   NOTFOUND: () => '/404'
}
