import { getBackground } from './main.helpers'
import { MainActions, MainState, MainAction } from './main.types'

const initialState: MainState = {
   title: 'Загрузка',
   background: getBackground()
}

export function mainReducer (state = initialState, action: MainAction): MainState
{
   switch (action.type) {
      case MainActions.TITLE_SET:
         return { ...state, title: action.payload }
      case MainActions.BACKGROUND_SET:
         return { ...state, background: action.payload }
      default:
         return state
   }
}
