import { Vector2 } from './engine/Vector2'

class Config
{
   public FPS_MAX = 30
   public DPI_SCALE = 2
   public DEV_MODE = false
   public TIME_SCALE = 1

   public FIGURES_DENSITY = 180
   public FIGURES_OFFSET = 90
   public FIGURES_SCALE = 1.2
   public FIGURES_COLOR = '#d5d5d5'

   public FIGURES_RANDOM = {
      RECTANGLE: {
         SIZE: new Vector2(15, 60),
         RADIUS: new Vector2(0, 15),
         ANGLE: new Vector2(0, 360),
         VELOCITY: new Vector2(0.005, 0.08),
         SELF_ANGLE: new Vector2(0, 360),
         ROTATE_VELOCITY: new Vector2(0.01, 0.05)
      },
      CIRCLE: {
         RADIUS: new Vector2(1, 35),
         ANGLE: new Vector2(0, 360),
         VELOCITY: new Vector2(0.005, 0.05)
      },
      PLUS: {
         SIZE: new Vector2(10, 50),
         ANGLE: new Vector2(0, 360),
         VELOCITY: new Vector2(0.005, 0.08),
         SELF_ANGLE: new Vector2(0, 360),
         ROTATE_VELOCITY: new Vector2(0.01, 0.05)
      },
      TRIANGLE: {
         RADIUS: new Vector2(10, 35),
         ANGLE: new Vector2(0, 360),
         VELOCITY: new Vector2(0.005, 0.08),
         SELF_ANGLE: new Vector2(0, 360),
         ROTATE_VELOCITY: new Vector2(0.01, 0.05)
      },
      HEXAGON: {
         RADIUS: new Vector2(8, 27),
         ANGLE: new Vector2(0, 360),
         VELOCITY: new Vector2(0.005, 0.08),
         SELF_ANGLE: new Vector2(0, 360),
         ROTATE_VELOCITY: new Vector2(0.01, 0.1)
      }
   }

   constructor ()
   {
      this.FIGURES_DENSITY = this.FIGURES_DENSITY * this.DPI_SCALE

      this.FIGURES_RANDOM.RECTANGLE.SIZE.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.RECTANGLE.RADIUS.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.RECTANGLE.VELOCITY.mul(this.DPI_SCALE).mul(this.TIME_SCALE)
      this.FIGURES_RANDOM.RECTANGLE.ROTATE_VELOCITY.mul(this.TIME_SCALE)

      this.FIGURES_RANDOM.CIRCLE.RADIUS.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.CIRCLE.VELOCITY.mul(this.DPI_SCALE).mul(this.TIME_SCALE)

      this.FIGURES_RANDOM.PLUS.SIZE.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.PLUS.VELOCITY.mul(this.DPI_SCALE).mul(this.TIME_SCALE)
      this.FIGURES_RANDOM.PLUS.ROTATE_VELOCITY.mul(this.TIME_SCALE)

      this.FIGURES_RANDOM.TRIANGLE.RADIUS.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.TRIANGLE.VELOCITY.mul(this.DPI_SCALE).mul(this.TIME_SCALE)
      this.FIGURES_RANDOM.TRIANGLE.ROTATE_VELOCITY.mul(this.TIME_SCALE)

      this.FIGURES_RANDOM.HEXAGON.RADIUS.mul(this.DPI_SCALE).mul(this.FIGURES_SCALE)
      this.FIGURES_RANDOM.HEXAGON.VELOCITY.mul(this.DPI_SCALE).mul(this.TIME_SCALE)
      this.FIGURES_RANDOM.HEXAGON.ROTATE_VELOCITY.mul(this.TIME_SCALE)
   }
}

export const config = new Config()
