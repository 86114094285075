export class Vector2
{
   public x: number
   public y: number

   constructor (x?: number, y?: number)
   {
      this.x = x ?? 0
      this.y = y ?? 0
   }

   /* < Статические методы > */
   public static copy (vector: Vector2): Vector2
   {
      return new Vector2(vector.x, vector.y)
   }

   public static radians (degrees: number): number
   {
      return degrees * (Math.PI / 180)
   }

   public static degrees (radians: number): number
   {
      return radians * (180 / Math.PI)
   }

   /* < Методы экземпляра > */
   public set (...arg: [Vector2] | [number, number] | [number]): this
   {
      const x = (arg[0] instanceof Vector2 ? arg[0].x : arg[0])
      const y = (arg[0] instanceof Vector2 ? arg[0].y : arg[1] ?? arg[0])
      this.x = x
      this.y = y
      return this
   }

   public add (...arg: [Vector2] | [number, number] | [number]): this
   {
      const x = (arg[0] instanceof Vector2 ? arg[0].x : arg[0])
      const y = (arg[0] instanceof Vector2 ? arg[0].y : arg[1] ?? arg[0])
      this.x += x
      this.y += y
      return this
   }

   public sub (...arg: [Vector2] | [number, number] | [number]): this
   {
      const x = (arg[0] instanceof Vector2 ? arg[0].x : arg[0])
      const y = (arg[0] instanceof Vector2 ? arg[0].y : arg[1] ?? arg[0])
      this.x -= x
      this.y -= y
      return this
   }

   public mul (...arg: [Vector2] | [number, number] | [number]): this
   {
      const x = (arg[0] instanceof Vector2 ? arg[0].x : arg[0])
      const y = (arg[0] instanceof Vector2 ? arg[0].y : arg[1] ?? arg[0])
      this.x *= x
      this.y *= y
      return this
   }

   public div (...arg: [Vector2] | [number, number] | [number]): this
   {
      const x = (arg[0] instanceof Vector2 ? arg[0].x : arg[0])
      const y = (arg[0] instanceof Vector2 ? arg[0].y : arg[1] ?? arg[0])
      this.x /= x
      this.y /= y
      return this
   }

   public negate (key?: 'x' | 'y' | 'xy'): this
   {
      if (!key || key === 'xy') {
         this.x = -this.x
         this.y = -this.y
      }

      if (key === 'x') this.x = -this.x
      if (key === 'y') this.y = -this.y

      return this
   }

   public fixed (remainder: number): this
   {
      const number = parseInt(`1${'0'.repeat(remainder)}`)
      this.x = Math.trunc(this.x * number) / number
      this.y = Math.trunc(this.y * number) / number
      return this
   }

   public clone (): Vector2
   {
      return Vector2.copy(this)
   }

   public angle (vector: Vector2): number
   {
      const difference = this.clone().sub(vector)
      const radians = Math.atan2(difference.y, difference.x)
      const degrees = Vector2.degrees(radians) % 360
      return (degrees < 0 ? degrees + 360 : degrees)
   }

   public distance (vector: Vector2): number
   {
      return Math.sqrt(Math.pow(vector.x - this.x, 2) + Math.pow(vector.y - this.y, 2))
   }
}
