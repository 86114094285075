import { FC, useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

import { utilsService } from 'common/services/utils'
import { useActions } from 'hooks/useActions'
import { useApi } from 'hooks/useApi'

import { Konami } from './Konami'

export const Master: FC = () => {
   const { UserSet } = useActions()
   const [password, setPassword] = useState<string>('')

   const handler = useCallback(() => {
      const response = prompt('Введите мастер пароль')
      setPassword(response || '')
   }, [])

   const method = useCallback(() => utilsService.masterPassword({ password }), [password])
   const [config, execute] = useApi(method)

   useEffect(() => {
      if (password !== '') execute()
   }, [password]) // eslint-disable-line

   useEffect(() => {
      if (config.data) {
         const { type, data } = config.data

         if (type === 'admin')
            toast.success(
               data.admin
                  ? 'Теперь вы являетесь администратором'
                  : 'Вы больше не являетесь администратором'
            )
         if (type === 'changer')
            toast.success(
               data.changer ? 'Теперь вы являетесь редактором' : 'Вы больше не являетесь редактором'
            )

         if (type === 'admin' || type === 'changer') {
            UserSet(data)
         }

         setPassword('')
      }
   }, [config.data]) // eslint-disable-line

   return <Konami handler={handler} />
}
