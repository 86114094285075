import { combineReducers } from 'redux'
import { mainReducer } from './main/main.reducer'
import { userReducer } from './user/user.reducer'

export const rootReducer = combineReducers({
   main: mainReducer,
   user: userReducer
})

export type RootState = ReturnType<typeof rootReducer>
