import { Vector2 } from './Vector2'

class Random
{
   public number (min: number, max: number): number
   {
      const digits = Math.max(this.getDigits(min), this.getDigits(max))
      return +(Math.random() * (max - min) + min).toFixed(digits)
   }

   public numberByVector (vector: Vector2): number
   {
      return this.number(vector.x, vector.y)
   }

   private getDigits (number: number): number
   {
      const value = number.toString()
      return (value.includes('.') && value.split('.').pop()?.length) || 0
   }
}

export const random = new Random()
