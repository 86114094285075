import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { RegisterState, ForbiddenState } from 'common/types/locations.types'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'

import { PageProps } from './Page.types'

export const Page = ({ title, auth, admin, changer, Component }: PageProps) => {
   const location = useLocation()
   const $user = useSelector(state => state.user)
   const { MainTitleSet } = useActions()

   const unauthorized = auth && !$user
   const forbidden =
      (!$user && (admin || changer)) || (admin && !$user?.admin) || (changer && !$user?.changer)

   useEffect(() => {
      if (!unauthorized && !forbidden) MainTitleSet(title)
   }, [unauthorized, forbidden, title]) // eslint-disable-line

   if (unauthorized) {
      const state: RegisterState = {
         from: `${location.pathname}${location.search}`
      }

      return (
         <Navigate
            to={PathsConfig.LOGIN()}
            state={state}
            replace={true}
         />
      )
   }

   if (forbidden) {
      const state: ForbiddenState = {
         from: `${location.pathname}${location.search}`
      }

      return (
         <Navigate
            to={PathsConfig.FORBIDDEN()}
            state={state}
            replace={true}
         />
      )
   }

   return <Component />
}
