export interface MainState {
   title: string
   background: boolean
}

export enum MainActions {
   TITLE_SET = 'MAIN_TITLE_SET',
   BACKGROUND_SET = 'MAIN_BACKGROUND_SET'
}

interface TITLE_SET {
   type: MainActions.TITLE_SET
   payload: string
}

interface BACKGROUND_SET {
   type: MainActions.BACKGROUND_SET
   payload: boolean
}

export type MainAction = (
     TITLE_SET
   | BACKGROUND_SET
)
