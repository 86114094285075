import { UserSchema } from 'common/types/schemas/user.schema'

export type UserState = UserSchema | null

export enum UserActions {
   SET = 'USER_SET'
}

interface SET {
   type: UserActions.SET
   payload: UserSchema | null
}

export type UserAction = (
   SET
)
