import { Dispatch } from 'redux'
import { RootState } from 'common/store/reducers'
import { saveBackground } from './main.helpers'
import { MainActions, MainAction } from './main.types'

export function MainTitleSet (title: string)
{
   return (dispatch: Dispatch<MainAction>, getState: () => RootState) => {
      const state = getState()

      if (state.main.title !== title) {
         document.title = `${title} | MEDRUT QUIZ`

         dispatch({
            type: MainActions.TITLE_SET,
            payload: title
         })
      }
   }
}

export function MainBackgroundSet (background: boolean)
{
   return (dispatch: Dispatch<MainAction>) => {
      dispatch({ type: MainActions.BACKGROUND_SET, payload: background })
      saveBackground(background)
   }
}
