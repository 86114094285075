import { Model } from '../models/Model.class'

export class Engine
{
   public readonly container: HTMLElement
   public readonly fpsMax: number

   public readonly canvas: HTMLCanvasElement
   public readonly ctx: CanvasRenderingContext2D

   public readonly dpiScale: number
   public width: number
   public height: number

   private models: Model[]

   private timeStamp: number
   private timer: number
   private loopId: number

   private status: boolean
   private timeout: NodeJS.Timeout | null

   private externalResizeHandler = () => {}
   private resizeHandler = () => {}

   constructor (container: HTMLElement, fpsMax: number, dpiScale: number)
   {
      this.container = container
      this.fpsMax = fpsMax
      this.dpiScale = dpiScale

      this.canvas = document.createElement('canvas')
      this.ctx = this.canvas.getContext('2d')!

      this.width = 0
      this.height = 0

      this.models = []

      this.timeStamp = 0
      this.timer = 0
      this.loopId = 0

      this.status = false
      this.timeout = null

      this.resizeHandler = this.debouncedResize.bind(this)
      this.resize()
   }

   /* < Обновление элементов сцены перед отрисовкой > */
   private update (): void
   {
      this.models.forEach(model => model.update())
   }

   /* < Отрисовка сцены > */
   private render (): void
   {
      this.ctx.clearRect(0, 0, this.width, this.height)
      this.models.forEach(model => model.render())
   }

   /* < Цикл обновления и отрисовки сцены > */
   private loop (timeStamp: number): void
   {
      const interval = 1000 / this.fpsMax
      const delta = timeStamp - this.timeStamp

      this.update()
      this.timeStamp = timeStamp

      if (this.timer > interval) {
         this.render()
         this.timer = 0
      } else {
         this.timer += delta
      }

      this.loopId = window.requestAnimationFrame(this.loop.bind(this))
   }

   /* < Установка элементов на сцену > */
   public setModels (models: Model[]): void
   {
      this.models = models
   }

   /* < Установка внешнего обработчика событий на изменение размера сцены > */
   public addExternalResizeHandler (handler: () => void)
   {
      this.externalResizeHandler = handler
   }

   /* < Запуск движка из вне > */
   public start (): void
   {
      if (!this.status) {
         this.container.appendChild(this.canvas)
         this.loop(this.timeStamp)
         window.addEventListener('resize', this.resizeHandler)
         window.addEventListener('orientationchange', this.resizeHandler)
         this.status = true
      }
   }

   /* < Остановка движка из вне > */
   public stop (): void
   {
      if (this.status) {
         window.removeEventListener('resize', this.resizeHandler)
         window.removeEventListener('orientationchange', this.resizeHandler)
         window.cancelAnimationFrame(this.loopId)
         this.container.removeChild(this.canvas)
         this.status = false
      }
   }

   /* < Обработчик события изменения размеров окна > */
   private resize (): void
   {
      const width = this.container.clientWidth
      const height = this.container.clientHeight

      if (this.width !== width || this.height !== height) {
         this.width = width * this.dpiScale
         this.height = height * this.dpiScale

         this.canvas.width = this.width
         this.canvas.height = this.height
         this.canvas.style.width = width + 'px'
         this.canvas.style.height = height + 'px'

         this.externalResizeHandler()
      }
   }

   /* < DEBOUNCED Обработчик события изменения размеров окна > */
   private debouncedResize (): void
   {
      this.timeout && clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
         this.resize()
         this.timeout && clearTimeout(this.timeout)
      }, 1000)
   }
}
