import { Vector2 } from './Vector2'

interface StyleOptions {
   type?: 'both' | 'fill' | 'stroke'
   fill?: string
   stroke?: string
   width?: number
   font?: string
}

interface RectangleOptions extends Pick<StyleOptions, 'type' | 'fill' | 'stroke' | 'width'> {
   pos: Vector2
   size: Vector2
   rad?: number
}

interface CircleOptions extends Pick<StyleOptions, 'type' | 'fill' | 'stroke' | 'width'> {
   pos: Vector2
   rad: number
}

interface TriangleOptions extends Pick<StyleOptions, 'type' | 'fill' | 'stroke' | 'width'> {
   pos: Vector2
   rad: number
}

interface LineOptions extends Pick<StyleOptions, 'stroke' | 'width'> {
   pos1: Vector2
   pos2: Vector2
}

interface TextOptions extends Pick<StyleOptions, 'type' | 'fill' | 'stroke' | 'width' | 'font'> {
   pos: Vector2
   text: string
}

export class Tools
{
   constructor (
      private readonly ctx: CanvasRenderingContext2D
   ) {}

   public rotate (pos: Vector2, size: Vector2, angle: number, callback: (position: Vector2) => void): void
   {
      const center = size.clone().div(2)
      const translate = pos.clone().add(center)
      const position = new Vector2(0, 0).sub(center)

      this.ctx.save()
      this.ctx.translate(translate.x, translate.y)
      this.ctx.scale(1, -1)
      this.ctx.rotate(Vector2.radians(angle))
      callback(position)
      this.ctx.restore()
   }

   public path (callback: (ctx: CanvasRenderingContext2D) => void): void
   {
      callback(this.ctx)
   }

   public rectangle ({ pos, size, rad, ...style }: RectangleOptions): void
   {
      const styles = this.getStyles(style)

      this.ctx.beginPath()
      this.ctx.roundRect(pos.x, pos.y, size.x, size.y, rad)
      this.ctx.closePath()

      if (styles.type === 'both' || styles.type === 'fill') {
         this.ctx.fillStyle = styles.fill
         this.ctx.fill()
      }

      if (styles.type === 'both' || styles.type === 'stroke') {
         this.ctx.strokeStyle = styles.stroke
         this.ctx.lineWidth = styles.width
         this.ctx.stroke()
      }
   }

   public circle ({ pos, rad, ...style }: CircleOptions): void
   {
      const styles = this.getStyles(style)

      this.ctx.beginPath()
      this.ctx.arc(pos.x, pos.y, rad, 0, Math.PI * 2)
      this.ctx.closePath()

      if (styles.type === 'both' || styles.type === 'fill') {
         this.ctx.fillStyle = styles.fill
         this.ctx.fill()
      }

      if (styles.type === 'both' || styles.type === 'stroke') {
         this.ctx.strokeStyle = styles.stroke
         this.ctx.lineWidth = styles.width
         this.ctx.stroke()
      }
   }

   public triangle ({ pos, rad, ...style }: TriangleOptions): void
   {
      const styles = this.getStyles(style)
      const x1 = pos.x + rad
      const y1 = pos.y
      const x2 = pos.x + rad * Math.cos(Math.PI * 2 / 3)
      const y2 = pos.y + rad * Math.sin(Math.PI * 2 / 3)
      const x3 = pos.x + rad * Math.cos(Math.PI * 4 / 3)
      const y3 = pos.y + rad * Math.sin(Math.PI * 4 / 3)

      this.ctx.beginPath()
      this.ctx.moveTo(x1, y1)
      this.ctx.lineTo(x2, y2)
      this.ctx.lineTo(x3, y3)
      this.ctx.lineTo(x1, y1)
      this.ctx.closePath()

      if (styles.type === 'both' || styles.type === 'fill') {
         this.ctx.fillStyle = styles.fill
         this.ctx.fill()
      }

      if (styles.type === 'both' || styles.type === 'stroke') {
         this.ctx.strokeStyle = styles.stroke
         this.ctx.lineWidth = styles.width
         this.ctx.stroke()
      }
   }

   public hexagon ({ pos, rad, ...style }: TriangleOptions): void
   {
      const styles = this.getStyles(style)
      const x1 = pos.x + rad
      const y1 = pos.y

      const x2 = pos.x + rad * Math.cos(Math.PI / 3)
      const y2 = pos.y + rad * Math.sin(Math.PI / 3)
      const x3 = pos.x + rad * Math.cos(Math.PI * 2 / 3)
      const y3 = pos.y + rad * Math.sin(Math.PI * 2 / 3)
      const x4 = pos.x + rad * Math.cos(Math.PI * 3 / 3)
      const y4 = pos.y + rad * Math.sin(Math.PI * 3 / 3)
      const x5 = pos.x + rad * Math.cos(Math.PI * 4 / 3)
      const y5 = pos.y + rad * Math.sin(Math.PI * 4 / 3)
      const x6 = pos.x + rad * Math.cos(Math.PI * 5 / 3)
      const y6 = pos.y + rad * Math.sin(Math.PI * 5 / 3)

      this.ctx.beginPath()
      this.ctx.moveTo(x1, y1)
      this.ctx.lineTo(x2, y2)
      this.ctx.lineTo(x3, y3)
      this.ctx.lineTo(x4, y4)
      this.ctx.lineTo(x5, y5)
      this.ctx.lineTo(x6, y6)
      this.ctx.lineTo(x1, y1)
      this.ctx.closePath()

      if (styles.type === 'both' || styles.type === 'fill') {
         this.ctx.fillStyle = styles.fill
         this.ctx.fill()
      }

      if (styles.type === 'both' || styles.type === 'stroke') {
         this.ctx.strokeStyle = styles.stroke
         this.ctx.lineWidth = styles.width
         this.ctx.stroke()
      }
   }

   public line ({ pos1, pos2, ...style }: LineOptions): void
   {
      const styles = this.getStyles(style)

      this.ctx.strokeStyle = styles.stroke
      this.ctx.lineWidth = styles.width

      this.ctx.beginPath()
      this.ctx.moveTo(pos1.x, pos1.y)
      this.ctx.lineTo(pos2.x, pos2.y)
      this.ctx.closePath()
      this.ctx.stroke()
   }

   public text ({ pos, text, ...style }: TextOptions): void
   {
      const styles = this.getStyles(style)
      this.ctx.font = styles.font

      if (styles.type === 'both' || styles.type === 'fill') {
         this.ctx.fillStyle = styles.fill
         this.ctx.fillText(text, pos.x, pos.y)
      }

      if (styles.type === 'both' || styles.type === 'stroke') {
         this.ctx.strokeStyle = styles.stroke
         this.ctx.lineWidth = styles.width
         this.ctx.strokeText(text, pos.x, pos.y)
      }
   }

   private getStyles (styles: StyleOptions): Required<StyleOptions>
   {
      return {
         type:   styles.type   || 'fill',
         fill:   styles.fill   || '#000000',
         stroke: styles.stroke || '#000000',
         width:  styles.width  || 1,
         font:   styles.font   ||'14px Arial'
      }
   }
}
