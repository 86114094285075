// prettier-ignore
export const ApiConfig = {
   AUTH_CODE_TOKEN_LENGTH: 5, // Длинна строки токена одноразового кода авторизации
   USERS_TOKEN_LENGTH: 34,    // Длинна строки токена авторизации
   USERS_VISIT_INTERVAL: 300, // Интервал времени между автоматическим обновлением последнего визита
   TESTS_TOKEN_LENGTH: 12,    // Длинна строки токена тестирований
   TESTS_MAX_LABELS: 30,      // Максимальное количество меток в тестировании
   QUIZZES_TOKEN_LENGTH: 12,  // Длинна строки токена опросов

   ENDPOINTS: {
      FILES_IMAGE: 'files/image',

      AUTH_REGISTER: 'auth.register',
      AUTH_REFRESH: 'auth.refresh',
      AUTH_LOGIN: 'auth.login',
      AUTH_RESET: 'auth.reset',
      AUTH_CREATE_CODE: 'auth.createCode',
      AUTH_LOGOUT: 'auth.logout',

      USERS_LIST: 'users.list',
      USERS_CREATE: 'users.create',
      USERS_UPDATE_NAME: 'users.updateName',
      USERS_UPDATE_TOKEN: 'users.updateToken',
      USERS_SET_ADMIN: 'users.setAdmin',
      USERS_SET_CHANGER: 'users.setChanger',
      USERS_REMOVE: 'users.remove',

      TESTS_LIST: 'tests.list',
      TESTS_PUBLIC_LIST: 'tests.publicList',
      TESTS_GET: 'tests.get',
      TESTS_CREATE: 'tests.create',
      TESTS_CHANGE: 'tests.change',
      TESTS_REMOVE: 'tests.remove',
      TESTS_CREATE_LABEL: 'tests.createLabel',
      TESTS_CHANGE_LABEL: 'tests.changeLabel',
      TESTS_REMOVE_LABEL: 'tests.removeLabel',
      TESTS_UPDATE_TOKEN: 'tests.updateToken',
      TESTS_SET_PRIVACY: 'tests.setPrivacy',
      TESTS_START: 'tests.start',
      TESTS_STOP: 'tests.stop',

      TESTS_QUESTIONS_LIST: 'tests.questions/list',
      TESTS_QUESTIONS_CREATE: 'tests.questions/create',
      TESTS_QUESTIONS_IMAGE: 'tests.questions/image',
      TESTS_QUESTIONS_CHANGE: 'tests.questions/change',
      TESTS_QUESTIONS_REMOVE: 'tests.questions/remove',

      TESTS_RESULTS_LIST: 'tests.results/list',
      TESTS_RESULTS_START: 'tests.results/start',
      TESTS_RESULTS_LABEL: 'tests.results/label',
      TESTS_RESULTS_CHANGE: 'tests.results/change',
      TESTS_RESULTS_FINISH: 'tests.results/finish',
      TESTS_RESULTS_REMOVE: 'tests.results/remove',

      QUIZZES_LIST: 'quizzes.list',
      QUIZZES_PUBLIC_LIST: 'quizzes.publicList',
      QUIZZES_GET: 'quizzes.get',
      QUIZZES_CREATE: 'quizzes.create',
      QUIZZES_IMAGE: 'quizzes.image',
      QUIZZES_CHANGE: 'quizzes.change',
      QUIZZES_REMOVE: 'quizzes.remove',
      QUIZZES_UPDATE_TOKEN: 'quizzes.updateToken',
      QUIZZES_SET_MULTIPLE: 'quizzes.setMultiple',
      QUIZZES_SET_COMMENTABLE: 'quizzes.setCommentable',
      QUIZZES_SET_ANONYMITY: 'quizzes.setAnonymity',
      QUIZZES_SET_PRIVACY: 'quizzes.setPrivacy',
      QUIZZES_START: 'quizzes.start',
      QUIZZES_STOP: 'quizzes.stop',
      QUIZZES_STATISTICS: 'quizzes.statistics',
      QUIZZES_VOTE_START: 'quizzes.voteStart',
      QUIZZES_VOTE_FINISH: 'quizzes.voteFinish',

      UTILS_MASTER_PASSWORD: 'utils.masterPassword'
   },

   BASE_URL: (process.env.NODE_ENV === 'production'
      ? 'https://api.medrut-quiz.ru/'
      : 'http://localhost:5000/'
   )
}
