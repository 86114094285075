import { FC, useState, useRef, useEffect } from 'react'
import { Flow } from 'components/shared/Animations/Flow'
import { KonamiProps } from './Konami.types'
import css from './Konami.module.scss'

const KONAMI_CODE = [
   ['ArrowUp', 'Numpad8', 'KeyW'],
   ['ArrowUp', 'Numpad8', 'KeyW'],
   ['ArrowDown', 'Numpad2', 'KeyS'],
   ['ArrowDown', 'Numpad2', 'KeyS'],
   ['ArrowLeft', 'Numpad4', 'KeyA'],
   ['ArrowRight', 'Numpad6', 'KeyD'],
   ['ArrowLeft', 'Numpad4', 'KeyA'],
   ['ArrowRight', 'Numpad6', 'KeyD'],
   ['KeyB', 'Comma'],
   ['KeyA', 'KeyF']
]

export const Konami: FC<KonamiProps> = ({ handler }) => {
   const [cursor, setCursor] = useState(0)
   const [visible, setVisible] = useState(false)
   const timeout = useRef<NodeJS.Timeout | null>(null)

   useEffect(() => {
      const keyDownHandler = (e: KeyboardEvent) => {
         setCursor(KONAMI_CODE[cursor]?.includes(e.code) ? cursor + 1 : 0)
      }

      if (cursor === KONAMI_CODE.length) {
         setCursor(0)
         setVisible(true)
      }

      document.addEventListener('keydown', keyDownHandler)

      return () => {
         document.removeEventListener('keydown', keyDownHandler)
      }
   }, [cursor])

   useEffect(() => {
      if (visible) {
         timeout.current = setTimeout(() => {
            setVisible(false)
            timeout.current && clearTimeout(timeout.current)
         }, 5000)
      }

      return () => {
         timeout.current && clearTimeout(timeout.current)
      }
   }, [visible])

   return (
      <Flow
         type="fade-right"
         active={visible}
      >
         <button
            className={css.konami}
            type="button"
            onClick={handler}
            tabIndex={-1}
         >
            <img
               src="/images/konami.png"
               alt="Пасхальное яйцо"
            />
         </button>
      </Flow>
   )
}
