import { UserActions, UserState, UserAction } from './user.types'

const initialState: UserState = null

export function userReducer (state = initialState, action: UserAction): UserState
{
   switch (action.type) {
      case UserActions.SET:
         return action.payload
      default:
         return state
   }
}
