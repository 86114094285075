import { FC, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { useSelector } from 'hooks/useSelector'

import { Layout } from './Layout'
import { Page } from './Page'

const Dev = lazy(() => import('components/routes/Troubles/Dev'))
const Home = lazy(() => import('components/routes/Home'))

const TestsList = lazy(() => import('components/routes/Tests/TestsList'))
const TestsListPublic = lazy(() => import('components/routes/Tests/TestsListPublic'))
const TestsCreate = lazy(() => import('components/routes/Tests/TestsCreate'))
const TestsDetails = lazy(() => import('components/routes/Tests/TestsDetails'))
const TestsPass = lazy(() => import('components/routes/Tests/TestsPass'))

const QuizzesList = lazy(() => import('components/routes/Quizzes/QuizzesList'))
const QuizzesListPublic = lazy(() => import('components/routes/Quizzes/QuizzesListPublic'))
const QuizzesCreate = lazy(() => import('components/routes/Quizzes/QuizzesCreate'))
const QuizzesDetails = lazy(() => import('components/routes/Quizzes/QuizzesDetails'))
const QuizzesPass = lazy(() => import('components/routes/Quizzes/QuizzesPass'))

const Users = lazy(() => import('components/routes/Users'))
const Settings = lazy(() => import('components/routes/Settings'))

const Register = lazy(() => import('components/routes/Register'))
const Login = lazy(() => import('components/routes/Login'))
const Auth = lazy(() => import('components/routes/Auth'))

const Support = Dev
const Forbidden = lazy(() => import('components/routes/Troubles/Forbidden'))
const NotFound = lazy(() => import('components/routes/Troubles/NotFound'))

export const Router: FC = () => {
   const $user = useSelector(state => state.user)

   return (
      <Routes>
         <Route
            path={PathsConfig.HOME()}
            element={<Layout />}
         >
            <Route
               index={true}
               element={
                  <Page
                     title="Главная"
                     auth={true}
                     Component={Home}
                  />
               }
            />

            <Route
               path={PathsConfig.USERS()}
               element={
                  <Page
                     title="Пользователи"
                     auth={true}
                     admin={true}
                     Component={Users}
                  />
               }
            />

            <Route
               path={PathsConfig.SETTINGS()}
               element={
                  <Page
                     title="Настройки"
                     auth={true}
                     Component={Settings}
                  />
               }
            />

            <Route path={PathsConfig.TESTS()}>
               <Route
                  index={true}
                  element={
                     <Page
                        title={$user?.admin ? 'Список тестирований' : 'Мои тестирования'}
                        auth={true}
                        changer={true}
                        Component={TestsList}
                     />
                  }
               />

               <Route
                  path={PathsConfig.TESTS_PUBLIC()}
                  element={
                     <Page
                        title="Публичные тестирования"
                        auth={true}
                        Component={TestsListPublic}
                     />
                  }
               />

               <Route
                  path={PathsConfig.TESTS_CREATE()}
                  element={
                     <Page
                        title="Новое тестирование"
                        auth={true}
                        changer={true}
                        Component={TestsCreate}
                     />
                  }
               />

               <Route
                  path={PathsConfig.TESTS_DETAILS(':testId')}
                  element={
                     <Page
                        title="Детали тестирования"
                        auth={true}
                        changer={true}
                        Component={TestsDetails}
                     />
                  }
               />

               <Route
                  path={PathsConfig.TESTS_PASS(':token')}
                  element={
                     <Page
                        title="Прохождение тестирования"
                        auth={true}
                        Component={TestsPass}
                     />
                  }
               />
            </Route>

            <Route path={PathsConfig.QUIZZES()}>
               <Route
                  index={true}
                  element={
                     <Page
                        title={$user?.admin ? 'Список опросов' : 'Мои опросы'}
                        auth={true}
                        changer={true}
                        Component={QuizzesList}
                     />
                  }
               />

               <Route
                  path={PathsConfig.QUIZZES_PUBLIC()}
                  element={
                     <Page
                        title="Публичные опросы"
                        auth={true}
                        Component={QuizzesListPublic}
                     />
                  }
               />

               <Route
                  path={PathsConfig.QUIZZES_CREATE()}
                  element={
                     <Page
                        title="Новый опрос"
                        auth={true}
                        changer={true}
                        Component={QuizzesCreate}
                     />
                  }
               />

               <Route
                  path={PathsConfig.QUIZZES_DETAILS(':quizId')}
                  element={
                     <Page
                        title="Детали опроса"
                        auth={true}
                        changer={true}
                        Component={QuizzesDetails}
                     />
                  }
               />

               <Route
                  path={PathsConfig.QUIZZES_PASS(':token')}
                  element={
                     <Page
                        title="Прохождение опроса"
                        auth={true}
                        Component={QuizzesPass}
                     />
                  }
               />
            </Route>

            <Route
               path={PathsConfig.REGISTER()}
               element={
                  <Page
                     title="Регистрация"
                     Component={Register}
                  />
               }
            />

            <Route
               path={PathsConfig.LOGIN()}
               element={
                  <Page
                     title="Вход"
                     Component={Login}
                  />
               }
            />

            <Route
               path={PathsConfig.AUTH(':token')}
               element={
                  <Page
                     title="Вход по ссылке"
                     Component={Auth}
                  />
               }
            />

            <Route
               path={PathsConfig.SUPPORT()}
               element={
                  <Page
                     title="Тех-поддержка"
                     Component={Support}
                  />
               }
            />

            <Route
               path={PathsConfig.FORBIDDEN()}
               element={
                  <Page
                     title="403"
                     Component={Forbidden}
                  />
               }
            />

            <Route
               path={PathsConfig.NOTFOUND()}
               element={
                  <Page
                     title="404"
                     Component={NotFound}
                  />
               }
            />

            <Route
               path="*"
               element={
                  <Navigate
                     to={PathsConfig.NOTFOUND()}
                     replace={true}
                  />
               }
            />
         </Route>
      </Routes>
   )
}
