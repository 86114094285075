import { Engine } from '../engine/Engine'
import { Tools } from '../engine/Tools'

export abstract class Model
{
   constructor (
      protected readonly engine: Engine,
      protected readonly tools: Tools
   ) {}

   public abstract update (): void
   public abstract render (): void
}
