import { StorageKeys } from 'common/unique'

export function getBackground (): boolean
{
   try {
      const value = localStorage.getItem(StorageKeys.MAIN_BACKGROUND) || ''
      const background = JSON.parse(value)

      if (!value || typeof background !== 'boolean') {
         throw 1 // eslint-disable-line
      }

      return background
   }
   catch (err: unknown) {
      localStorage.removeItem(StorageKeys.MAIN_BACKGROUND)
      return true
   }
}

export function saveBackground (background: boolean): void
{
   localStorage.setItem(StorageKeys.MAIN_BACKGROUND, String(background))
}
