import { Engine } from './engine/Engine'
import { Tools } from './engine/Tools'
import { Vector2 } from './engine/Vector2'
import { random } from './engine/random'
import { Model } from './models/Model.class'

import { Rectangle } from './models/Rectangle'
import { Circle } from './models/Circle'
import { Plus } from './models/Plus'
import { Triangle } from './models/Triangle'
import { Hexagon } from './models/Hexagon'

import { config } from './config'

export class Figures
{
   private readonly engine: Engine
   private readonly tools: Tools

   constructor (container: HTMLElement)
   {
      this.engine = new Engine(container, config.FPS_MAX, config.DPI_SCALE)
      this.tools = new Tools(this.engine.ctx)
      this.engine.addExternalResizeHandler(this.fillModels.bind(this))
   }

   public start (): void
   {
      this.fillModels()
      this.engine.start()
   }

   public stop (): void
   {
      this.engine.setModels([])
      this.engine.stop()
   }

   private fillModels (): void
   {
      const dotsWidth = Math.floor(this.engine.width / config.FIGURES_DENSITY)
      const dotsHeight = Math.floor(this.engine.height / config.FIGURES_DENSITY)
      const areaWidth = dotsWidth * config.FIGURES_DENSITY
      const areaHeight = dotsHeight * config.FIGURES_DENSITY
      const offsetWidth = (this.engine.width - areaWidth) / 2
      const offsetHeight = (this.engine.height - areaHeight) / 2
      const models: Model[] = []

      for (let x = 0; x <= areaWidth; x += config.FIGURES_DENSITY) {
         for (let y = 0; y <=  areaHeight; y += config.FIGURES_DENSITY) {
            const pos = new Vector2(x + offsetWidth, y + offsetHeight)
            models.push(this.getRandomFigure(pos))
         }
      }

      this.engine.setModels(models)
   }

   private getRandomFigure (pos: Vector2): Model
   {
      const number = random.number(0, 4)

      if (number === 1) return Circle.randomize(this.engine, this.tools, pos)
      if (number === 2) return Plus.randomize(this.engine, this.tools, pos)
      if (number === 3) return Triangle.randomize(this.engine, this.tools, pos)
      if (number === 4) return Hexagon.randomize(this.engine, this.tools, pos)
      return Rectangle.randomize(this.engine, this.tools, pos)
   }
}
