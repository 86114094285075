import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'

const middlewares = (process.env.NODE_ENV === 'production'
   ? applyMiddleware(thunk)
   : composeWithDevTools(applyMiddleware(thunk))
)

export const store = createStore(rootReducer, middlewares)
