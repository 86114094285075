import { config } from '../config'
import { Engine } from '../engine/Engine'
import { Tools } from '../engine/Tools'
import { Vector2 } from '../engine/Vector2'
import { random } from '../engine/random'
import { Model } from './Model.class'

interface CircleProps {
   bPos: Vector2
   bSize: Vector2
   pos: Vector2
   rad: number
   ang: number
   vel: number
   color: string
}

export class Circle extends Model
{
   private bPos: Vector2
   private bSize: Vector2
   private pos: Vector2
   private rad: number
   private ang: number
   private vel: number
   private dir: Vector2
   private color: string

   constructor (engine: Engine, tools: Tools, props: CircleProps)
   {
      super(engine, tools)

      this.bPos = props.bPos
      this.bSize = props.bSize

      this.pos = props.pos
      this.rad = props.rad
      this.ang = props.ang
      this.vel = props.vel

      const radians = Vector2.radians(this.ang)

      this.dir = new Vector2(Math.cos(radians), Math.sin(radians)).mul(this.vel)
      this.color = props.color
   }

   public update (): void
   {
      this.pos.add(this.dir)

      const min = this.bPos
      const max = this.bPos.clone().add(this.bSize)

      if (this.pos.y > max.y || this.pos.y < min.y) this.dir.negate('y')
      if (this.pos.x > max.x || this.pos.x < min.x) this.dir.negate('x')
   }

   public render (): void
   {
      const width = Math.max(10, this.rad / 5)

      this.tools.circle({
         pos: this.pos,
         rad: this.rad,
         type: 'stroke',
         stroke: this.color,
         width
      })

      if (config.DEV_MODE) {
         this.tools.rectangle({
            pos: this.bPos,
            size: this.bSize,
            type: 'stroke',
            stroke: 'green',
            width: 1
         })

         this.tools.circle({
            pos: this.pos,
            rad: 5,
            type: 'fill',
            fill: 'green'
         })
      }
   }

   public static randomize (engine: Engine, tools: Tools, position: Vector2): Circle
   {
      const pos = new Vector2(
         random.number(position.x - config.FIGURES_OFFSET, position.x + config.FIGURES_OFFSET),
         random.number(position.y - config.FIGURES_OFFSET, position.y + config.FIGURES_OFFSET),
      )

      return new Circle(engine, tools, {
         bPos: position.sub(config.FIGURES_DENSITY / 2),
         bSize: new Vector2(config.FIGURES_DENSITY, config.FIGURES_DENSITY),
         pos,
         rad: random.numberByVector(config.FIGURES_RANDOM.CIRCLE.RADIUS),
         ang: random.numberByVector(config.FIGURES_RANDOM.CIRCLE.ANGLE),
         vel: random.numberByVector(config.FIGURES_RANDOM.CIRCLE.VELOCITY),
         color: config.FIGURES_COLOR
      })
   }
}
