import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer, Flip } from 'react-toastify'

import dayjs from 'dayjs'
import dayjsRU from 'dayjs/locale/ru'

import 'react-toastify/dist/ReactToastify.css'
import 'styles/toastify.scss'
import 'styles/normalize.scss'
import 'styles/main.scss'
import 'styles/styles.scss'

import { store } from 'common/store'
import { Router } from 'components/app/Router'
import { Refresh } from 'components/app/Refresh'
import { Cookies } from 'components/app/Cookies'
import { Master } from 'components/app/Master'
import { Background } from 'components/app/Background'

export const App: FC = () => (
   <Provider store={store}>
      <BrowserRouter>
         <Router />
         <Refresh />
         <Cookies />
         <Master />
         <Background />

         <ToastContainer
            theme="light"
            limit={7}
            autoClose={5000}
            transition={Flip}
         />
      </BrowserRouter>
   </Provider>
)

dayjs.locale(dayjsRU.name)
