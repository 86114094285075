// prettier-ignore
export enum StorageKeys {
   COOKIES_WARNING = 'COOKIES_WARNING', // Предупреждение об использовании cookie файлов
   MAIN_BACKGROUND = 'MAIN_BACKGROUND', // Отображение анимированного фона

   USERS_REMOVE_WARNING = 'USERS_REMOVE_WARNING',                     // Предупреждение о удалении пользователей
   TESTS_QUESTIONS_CHANGE_WARNING = 'TESTS_QUESTIONS_CHANGE_WARNING', // Предупреждение о изменении вопросов в уже запущенных тестированиях
   QUIZZES_ANSWERS_CHANGE_WARNING = 'QUIZZES_ANSWERS_CHANGE_WARNING'  // Предупреждение о изменении вариантов ответа в уже запущенных опросах
}

// prettier-ignore
export enum ToastKeys {
   COPY_USER_LINK_WARNING = 'COPY_USER_LINK_WARNING', // Ключ для уведомления об успехе при копировании личной ссылки для входа в аккаунт
   COPY_USER_LINK_ERROR   = 'COPY_USER_LINK_ERROR',   // Ключ для уведомления об ошибке при копировании личной ссылки для входа в аккаунт
   COPY_CODE_WARNING      = 'COPY_CODE_WARNING',      // Ключ для уведомления об успехе при копировании одноразового кода авторизации для входа в аккаунт
   COPY_CODE_ERROR        = 'COPY_CODE_ERROR',        // Ключ для уведомления об ошибке при копировании одноразового кода авторизации для входа в аккаунт
   COPY_TEST_LINK_SUCCESS = 'COPY_TEST_LINK_SUCCESS', // Ключ для уведомления об успехе при копировании ссылки на тестирование
   COPY_TEST_LINK_ERROR   = 'COPY_TEST_LINK_ERROR',   // Ключ для уведомления об ошибке при копировании ссылки на тестирование
   COPY_QUIZ_LINK_SUCCESS = 'COPY_QUIZ_LINK_SUCCESS', // Ключ для уведомления об успехе при копировании ссылки на опрос
   COPY_QUIZ_LINK_ERROR   = 'COPY_QUIZ_LINK_ERROR',   // Ключ для уведомления об ошибке при копировании ссылки на опрос

   TESTS_QUESTIONS_RANDOM_ERROR = 'TESTS_QUESTIONS_RANDOM_ERROR' // Ключ для уведомления об ошибке при попытке установки случайного количества вопросов в тестирование в котором нет вопросов
}
