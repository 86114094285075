import { $request } from 'common/request'
import { ApiConfig } from 'configs/api.config'
import { UserSchema } from 'common/types/schemas/user.schema'
import { AuthCodeSchema } from 'common/types/schemas/authCode.schema'
import { AuthRegisterBody, AuthLoginBody } from './auth.types'

export const register = async (body: AuthRegisterBody) => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_REGISTER, body)
   return data
}

export const refresh = async () => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_REFRESH)
   return data
}

export const login = async (body: AuthLoginBody) => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_LOGIN, body)
   return data
}

export const reset = async () => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_RESET)
   return data
}

export const createCode = async () => {
   const { data } = await $request.post<AuthCodeSchema>(ApiConfig.ENDPOINTS.AUTH_CREATE_CODE)
   return data
}

export const logout = async () => {
   const { data } = await $request.post<true>(ApiConfig.ENDPOINTS.AUTH_LOGOUT)
   return data
}
